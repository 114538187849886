.featured-text-section {
    background-position: center;
    background-size: cover;
    padding: 0 !important;

    .section-content {
        background: rgba(0, 0, 0, 0.4);
        padding: 120px 70px;

        @media(max-width: $media_md) {
            padding: 50px;
        }

        .section-title {
            font-size: 30px;
            color: #fff;
            font-weight: 400;
        }

        .title-image {
            display: block;
            margin-bottom: 15px;
        }

        .section-separator {
            background: color('primary');
            color: color('primary');
            margin: 20px auto !important;
            height: 1px;
        }

        .section-text {
            text-align: left !important;
            clear: both;
            margin-top: 0 !important;

            p {
                font-size: 15px;
                color: #fff;
                width: 70%;

                @media(max-width: $media_xl) {
                    width: 100%;
                }
            }
        }

        .section-btn {
            font-weight: 500;
            border-radius: 0;
            background: #FFF;
            color: #000 !important;
            font-size: 15px;
            margin-top: 15px;
            border: 0;
        }
    }
}