html {
    body {
        #header {
            .social-container .social-link {
                padding-left: 15px;
                border-right: 1px solid #363636 !important;

                &:first-of-type {
                    border-left: 1px solid #363636 !important;
                }

                &:last-of-type {
                    margin-right: 15px;
                }
            }
        }
    }
}