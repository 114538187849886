.banners-wrapper {
    padding-top: 50px;

    &:last-child {
        padding-bottom: 50px;
    }

    @media(max-width: 768px) {
        padding-top: 0;
        padding-bottom: 0;
    }

    .banner-wrapper {
        display: flex;
        color: color('white');
        text-decoration: none;
        position: relative;

        @media(max-width: 768px) {
            margin-top: 50px;
        }

        a {
            width: 100%;
            display: block;
            height: 300px;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;

            .banner-content-wrapper {
                position: absolute;
                bottom: 0;
                padding: 30px;
                font-family: font(main);

                h2 {
                    font-size: 28px;
                    position: relative;
                    font-weight: 600;
                    text-transform: uppercase;

                    @media(max-width: 768px) {
                        font-size: 22px;
                    }
                }

                .separator {
                    height: 2px;
                    width: 100px;
                    margin: 10px 0;
                    background-color: color('terniary');
                }

                p {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.5;
                    text-decoration: none;
                }
            }
        }
    }
}

.home-double-banners {
    .row {
        height: 500px;
        background-size: cover;
        background-repeat: no-repeat;

        .content {
            padding: 100px 350px 100px 50px;
            background-color: #3e4659;
            height: 500px;
            opacity: 0.7;

            @media (max-width: 720px){
                padding: 50px;
            }

            .banner-content-wrapper {
                h2{
                    color: color('white');
                    font-size: 32px;
                    font-weight: 300;
                    margin-bottom: 20px;
                    @media (max-width: 720px){
                        font-size: 25px;
                    }
                }
                p{
                    color: color('white');
                    padding-bottom: 20px;
                    line-height: 1.6;

                    @media (max-width: 720px){
                        max-height: 268px;
                        overflow: hidden;
                    }
                }
                .separator{
                    height: 1px;
                    background: white;
                    width: 130px;
                    opacity: 0.4;
                }
                .banner-link{
                    border: 1px solid white;
                    padding: 10px 20px;
                    background-color: white;
                    color: black;
                    font-weight: 600;
                    font-size: 15px;
                    text-decoration: none;

                    &:hover{
                        background-color: #c9c7c7;
                    }
                }

            }
        }

    }
}

.banners-cards-wrapper {
    background: #f7f7f7;
    padding-top: 75px;
    padding-bottom: 45px;

    img {
        width: 100%;
        max-width: 100%;
    }

    .banner-card {
        margin-bottom: 30px;

        .banner-content-wrapper {
            border-left: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
            padding: 25px;

            h2 {
                font-size: 24px;
                color: #565656;
                font-weight: 400;
                text-transform: uppercase;
            }

            p {
                font-size: 14px;
                color: #737980;
                margin: 25px 0;
            }

            a {
                font-size: 13px;
                color: #5f98e4;
                font-weight: 500;
            }
        }
    }
}